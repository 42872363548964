import { ONE_MB_IN_BYTES } from "../shared/Constants";
import { useState, forwardRef, useEffect, useRef, useCallback, useImperativeHandle, useMemo, memo } from "react";
import { getFileExtension } from "../shared/Helpers/Utils";
import ModelState from "../shared/Helpers/ModelState";

const FileUpload = forwardRef(({ files, setFiles, placeholder = "No file selected", className = "", multiple=false, valid, setValid, name, validationOptions=[], allowedExtensions=[],
  required, requiredErrorMessage="The file is required." }, ref) => {
  const [isDisplayingError, setIsDisplayingError] = useState(false); // after first blur this is set to true and stays true
  const [errorMessage, setErrorMessage] = useState("");
  const [title, setTitle] = useState(null);
  const fileInputRef = useRef(null);

  const commaSeparatedAllowedExtensions = useMemo(() => allowedExtensions.map(extension => `.${extension}`).join(', '), [allowedExtensions]);

  useImperativeHandle(ref, () => ({
    setTitle: (fileName) => {
      setTitle(fileName);
      if (fileName) {
        setFiles((oldFiles) => {
          if (!oldFiles.length)
            return oldFiles;
          else
            return [];
        });
        const dataTransfer = new DataTransfer(); // .files will not accept [] so use dataTransfer.files
        fileInputRef.current.files = dataTransfer.files;
      }
    }
  }), [setFiles]);

  useEffect(() => {
    if (name) {
      ModelState.register(name, (errorMsg) => {
        setErrorMessage(errorMsg);
        setIsDisplayingError(true);
        setValid(false);
      }, () => {
        setErrorMessage("");
        setIsDisplayingError(false);
      })
    }

    return () => ModelState.unRegister(name);
  }, [name, setValid]); // on mount

  const validate = useCallback(async (files) => {
    let validationResult = true;

    if (!title) {
      if (required && files.length === 0) {
          validationResult = false;
          if (isDisplayingError)
            setErrorMessage(requiredErrorMessage);
      }
      if (validationResult) {
        let invalidFileExtensions = [];
        for (const file of files) {
          const extension = getFileExtension(file);
          if (!allowedExtensions.some((allowedExtension) => allowedExtension === extension)) {
            invalidFileExtensions.push(extension);
          }
        }
        if (invalidFileExtensions.length) {
          validationResult = false;
          if (isDisplayingError)
            setErrorMessage(`The selected file format is not allowed: ${invalidFileExtensions.map((invalidExtension) => `.${invalidExtension.toUpperCase()}`).join(', ')}`);
        }
      }
      if (validationResult && files.length) {
        for (const validationOption of validationOptions) {
            const isValid = await validationOption.validate(files);
            if (!isValid) {
                validationResult = false;
                if (isDisplayingError)
                  setErrorMessage(validationOption.errorMessage);
                break;
            }
        }
      }
    }

    if (validationResult) {
      fileInputRef.current.removeAttribute("data-invalid");
      setErrorMessage("");
    }
    else if (!validationResult && isDisplayingError)
      fileInputRef.current.setAttribute("data-invalid", "");

    setValid(validationResult);
  }, [required, setValid, validationOptions, allowedExtensions, requiredErrorMessage, setErrorMessage, isDisplayingError, title]);

  useEffect(() => {
      validate(files);
  }, [files, required, validationOptions, validate]);

  return (
    <>
      <label className={"fileInput " + className}>
        <input type="file" id="fromFile" accept={commaSeparatedAllowedExtensions} className={"d-none"}
        onChange={(e) => {
          setFiles(e.target.files);

          setIsDisplayingError(true);
        }}
        multiple={multiple} ref={fileInputRef}
        />
        <label className={"label-leading-trim" + (files.length || title ? "" : " opacity-50")}>{files.length ? Array.from(files).map(file => file.name).join(', ') : title || placeholder}</label>
        <button type="button" className="btn btn-primary">...</button>
      </label>
      {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
    </>
    )
});


/**
 * Slices a file into smaller chunks and uploads it.
 * @param {File|Blob} file - The file to be uploaded.
 * @param {string} url - The url to upload to.
 * @param {function(number) : void} onChunkUploaded - Called after each chunk gets uploaded. Can be used for progress tracking.
 * @param {function() : void} onFileUploaded - Called after all the chunks were sucessfuly uploaded.
 * @param {function() : void} onError - Called if an error is encountered.
 * @returns {void}
 */
export const uploadFileInChunks = (file, url, onChunkUploaded, onFileUploaded, onError) => {
  let progress = 0;

  const temporaryFileGuid = crypto.randomUUID();
  
  const chunkSize = ONE_MB_IN_BYTES;
  const chunks = Math.ceil(file.size / chunkSize);
  let currentChunk = 0;
  const uploadFileChunk = (start, end) => {
      const blob = file.slice(start, end);

      const formData = new FormData();
      formData.append("chunkMetadata", JSON.stringify({ FileGuid: temporaryFileGuid, Index: currentChunk, TotalCount: chunks }));
      formData.append('file', blob, file.name);
      fetch(url, {
          method: 'POST',
          body: formData,
      })
      .then((response) => {
          if (response.ok) {
              currentChunk++;

              if (currentChunk < chunks) {
                  progress = Math.round((currentChunk / chunks) * 100);
                  if (onChunkUploaded)
                    onChunkUploaded(progress);
                  // Continue with the next chunk
                  const nextStart = end;
                  const nextEnd = Math.min(end + chunkSize, file.size);
                  uploadFileChunk(nextStart, nextEnd);
              } else {
                  if (onFileUploaded)
                    onFileUploaded();
              }
          } else {
              if (onError)
                onError();
              console.error('Failed to upload file chunk:', response.statusText);
          }
      })
      .catch((error) => {
          if (onError)
            onError();
          console.error('Error uploading file:', error);
      });
  };
  const initialChunkSize = Math.min(chunkSize, file.size);
  uploadFileChunk(0, initialChunkSize);
};

export default memo(FileUpload);
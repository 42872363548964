import { useState, useRef, useEffect, memo } from 'react';
import { DECIMAL_SEPARATOR } from '../shared/Constants';

const FloorSeparator = ({floorSeparations, setFloorSeparations, valid, setValid, isVisible = true}) => { // if this component is collapsed, browser throws error because it tries to validate input fields inside it on submit but does not find them, so add disabled to input elements inside it when collapsed(!isVisible)
    const [floorCount, setFloorCount] = useState(4);
    const [debouncedFloorCount, setDebouncedFloorCount] = useState(4); // wait some time after user has finished typing, only then update the floor separations count
    const debounceFloorCountTimeout = useRef(null);

    const [areFloorHeightsInAscendingOrder, setAreFloorHeightsInAscendingOrder] = useState(true);
    const [isDisplayingError, setIsDisplayingError] = useState(false); // after first blur this is set to true and stays true
    const [isDisplayingFloorHeightsAreNotInAscendingOrderError, setIsDisplayingFloorHeightsAreNotInAscendingOrderError] = useState(false);
    const hideFloorHeightsAreNotInAscendingOrderErrorTimeout = useRef(null);

    useEffect(() => {
        debounceFloorCountTimeout.current = setTimeout(() => {
            setDebouncedFloorCount(floorCount);
        }, 1500);

        return () => {
            if (debounceFloorCountTimeout.current)
                clearTimeout(debounceFloorCountTimeout.current);
        };
    }, [floorCount]);

    useEffect(() => {
        setAreFloorHeightsInAscendingOrder(true);
        setIsDisplayingError(false);
        setIsDisplayingFloorHeightsAreNotInAscendingOrderError(false);
        if (debouncedFloorCount) {
            setFloorSeparations((prevSeparations) => {
                const newFloorSeparations = [...prevSeparations];
                if (newFloorSeparations.length < debouncedFloorCount) {
                    for (let i = 0; i < debouncedFloorCount; i++) {
                        const floorSeparation = newFloorSeparations[i];

                        if (floorSeparation === undefined)
                            newFloorSeparations.push({index: i, floor: null, height: null});
                    }
                }

                newFloorSeparations.length = debouncedFloorCount;

                if (newFloorSeparations.length) {
                    let groundFloorIndex = newFloorSeparations.find(floorSeparation => floorSeparation.ground_floor)?.index;
                    let defaultFloorIndex = newFloorSeparations.find(floorSeparation => floorSeparation.default_floor)?.index;
                    if (!groundFloorIndex) {
                        newFloorSeparations[0].ground_floor = true;
                        groundFloorIndex = 0;
                    }
    
                    if (!defaultFloorIndex) {
                        if (defaultFloorIndex === undefined)
                            defaultFloorIndex = newFloorSeparations.length > 1 ? 1 : 0;
                        newFloorSeparations[defaultFloorIndex].default_floor = true;
                    }
                    let floor = -groundFloorIndex;
                    for (let i = 0; i < debouncedFloorCount; i++) {
                        newFloorSeparations[i].floor = floor++;
                    }
                }

                return newFloorSeparations;
            });
        }
    }, [debouncedFloorCount, setFloorSeparations]);

    function immediatelySetDebouncedFloorCount() {
        clearTimeout(debounceFloorCountTimeout.current);
        debounceFloorCountTimeout.current = null;
        setDebouncedFloorCount(floorCount);
    }

    useEffect(() => {
        let lastHeight = 0;
        let floorHeightsInAscendingOrder = true;
        let allFloorsHaveHeight = true;

        for (let i = 0; i < floorSeparations.length; i++) {
            const floorSeparation = floorSeparations[i];

            if (floorSeparation.height != null) {
                if (floorSeparation.height < lastHeight + 0.0999999999) {
                    floorHeightsInAscendingOrder = false;
                } else
                    lastHeight = floorSeparation.height;
            } else
                allFloorsHaveHeight = false;
        }
        setAreFloorHeightsInAscendingOrder(floorHeightsInAscendingOrder);
        if (hideFloorHeightsAreNotInAscendingOrderErrorTimeout.current)
            clearTimeout(hideFloorHeightsAreNotInAscendingOrderErrorTimeout.current);
        if (!floorHeightsInAscendingOrder && isDisplayingError)
            hideFloorHeightsAreNotInAscendingOrderErrorTimeout.current = setTimeout(() => setIsDisplayingFloorHeightsAreNotInAscendingOrderError(true), 1500);
        else if (floorHeightsInAscendingOrder)
            hideFloorHeightsAreNotInAscendingOrderErrorTimeout.current = setTimeout(() => setIsDisplayingFloorHeightsAreNotInAscendingOrderError(false), 1500);

        setValid(floorSeparations.length && floorHeightsInAscendingOrder && allFloorsHaveHeight);
    }, [floorSeparations, setValid, isDisplayingFloorHeightsAreNotInAscendingOrderError, isDisplayingError]);

    return (
        <div className='FloorSeparator'>
            <section className='d-flex justify-content-between align-items-baseline p-3'>
                <label htmlFor='DropDownAdvancedModelSettingsNumberOfFloors'>Number of Floors</label>
                <input id="DropDownAdvancedModelSettingsNumberOfFloors" type="number" min={1} max={100} className='form-control' value={floorCount} 
                onChange={(e) => {
                    if (parseFloat(e.target.value) > 100 || parseFloat(e.target.value) <= 0)
                        return;

                    setFloorCount(e.target.value)
                }}
                onKeyDown={(e) => {
                    if (e.key === DECIMAL_SEPARATOR || e.key === '+' || e.key === '-')
                        e.preventDefault();
                    else if (e.key === "Enter") {
                        immediatelySetDebouncedFloorCount();
                        e.preventDefault();
                    }
                }}
                onBlur={(_) => {
                    immediatelySetDebouncedFloorCount();
                }}
                disabled={!isVisible}/>
            </section>
            {isDisplayingFloorHeightsAreNotInAscendingOrderError && <p className={"error-message mb-3"}>Make sure each Floor's ceiling is following an ascending order. Minimum ceiling height is 0.1</p>}
            <div className="Floors">
                {floorSeparations.map((floorSeparation, index) => (
                    <section className='d-flex justify-content-between align-items-center floorSeparation' key={"FloorSeparation" + floorSeparation.index}>
                        <label htmlFor={'DropDownAdvancedModelSettingsFloorSeparation' + (floorSeparation.index)}>{floorSeparation.floor}.</label>
                        <div className='flex-grow-1 d-flex justify-content-end'>
                            <div className="form-check form-check-reverse">
                                <label className={"form-check-label" + (floorSeparation.ground_floor ? "" : " unchecked")} htmlFor={"DropDownAdvancedModelSettingsGroundFloor" + floorSeparation.index}>
                                    Ground Floor
                                </label>
                                <input className="form-check-input" type="radio" name="ground" id={"DropDownAdvancedModelSettingsGroundFloor" + floorSeparation.index} checked={floorSeparation.ground_floor || false}
                                onChange={(_) => setFloorSeparations(prev => {
                                    const newFloorSeparations = [...prev];
                                    let floor = -index;
                                    for (let i = 0; i < newFloorSeparations.length; i++) {
                                        newFloorSeparations[i].ground_floor = i === index ? true : undefined;
                                        newFloorSeparations[i].floor = floor++;
                                    }
        
                                    return newFloorSeparations;
                                })}
                                disabled={!isVisible}/>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="default" id={"DropDownAdvancedModelSettingsDefaultFloor" + floorSeparation.index} checked={floorSeparation.default_floor || false}
                                onChange={(_) => setFloorSeparations(prev => {
                                    const newFloorSeparations = [...prev];
                                    for (let i = 0; i < newFloorSeparations.length; i++)
                                        newFloorSeparations[i].default_floor = i === index ? true : undefined;
        
                                    return newFloorSeparations;
                                })}
                                disabled={!isVisible}/>
                                <label className={"form-check-label" + (floorSeparation.default_floor ? "" : " unchecked")} htmlFor={"DropDownAdvancedModelSettingsDefaultFloor" + floorSeparation.index}>
                                    Default Floor
                                </label>
                            </div>
                        </div>
                        <input id={"DropDownAdvancedModelSettingsFloorSeparation" + floorSeparation.index} type="number" min={0.1} step="0.1" className={'form-control' + 
                            (floorSeparations[index - 1] && floorSeparations[index - 1].height != null && floorSeparation.height != null
                                ? ((floorSeparations[index - 1].height + 0.099999999999 > floorSeparation.height && isDisplayingError) ? " invalid-input" : "") 
                                : (floorSeparation.height != null && floorSeparation.height < 0.099999999999 && isDisplayingError ? " invalid-input" : "")) // if height is less than the previous floorSeparation + 0.1 then it is invalid
                        } 
                        value={floorSeparation.height ?? ""} 
                        onKeyDown={(e) => {
                            if (e.key === '+' || e.key === '-')
                                e.preventDefault();
                        }}
                        onChange={(e) => {
                            if (e.target.value.startsWith('0') && e.target.value.length > 1 && e.target.value[1] !== DECIMAL_SEPARATOR) {// do not allow leading zeroes in numbers
                                e.target.value = "0"; // no idea why this needs to be done, given input is controlled the value should stay "0" but it does not and leading zeroes appear
                                return;
                            }
                            const decimals = e.target.value.toString().split(DECIMAL_SEPARATOR);
                            if (decimals.length > 1 && decimals[1].length > 1) // allow only 1 decimal
                                return;
                            setFloorSeparations(prev => {
                                const newFloorSeparations = [...prev];
                                newFloorSeparations[index].height = e.target.value ? parseFloat(e.target.value) : null;

                                return newFloorSeparations;
                            });
                        }}
                        onBlur={(_) => {
                            if (!areFloorHeightsInAscendingOrder) {
                                setIsDisplayingError(true);
                                setIsDisplayingFloorHeightsAreNotInAscendingOrderError(true);
                            } else if (isDisplayingFloorHeightsAreNotInAscendingOrderError) {
                                if (hideFloorHeightsAreNotInAscendingOrderErrorTimeout.current) {
                                    clearTimeout(hideFloorHeightsAreNotInAscendingOrderErrorTimeout.current);
                                    hideFloorHeightsAreNotInAscendingOrderErrorTimeout.current = null;
                                }
                                setIsDisplayingFloorHeightsAreNotInAscendingOrderError(false);
                            }
                        }}
                        required
                        disabled={!isVisible}/>
                    </section>
                ))}
            </div>
        </div>
    );
}

export default memo(FloorSeparator);
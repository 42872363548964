import React, { useMemo, memo } from "react";
import { reactSelectStyles } from "../shared/Constants";
import flags from 'react-phone-number-input/flags'
import countryList from 'react-select-country-list';
import FloatedSelect from "./FloatedSelect";

export const extendedReactSelectStyles = {
    ...reactSelectStyles,
    input: (base, { value }) => ({ // move input by the size of the flag and use unknown flag svg while searching for a country
      ...reactSelectStyles.input(base),
      alignItems: 'center',
      display: 'flex',
      flexDirection: "row-reverse",
      ":before": (value ? {
        ...reactSelectStyles.input(base)[":before"],
        content: '""',
        minWidth: "25px",
        height: "18px",
        filter: "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(255deg) brightness(104%) contrast(105%)",
        backgroundImage: 'url("data:image/svg+xml,%3Csvg%20class%3D%22PhoneInputCountryIconImg%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2075%2050%22%3E%3Ctitle%3EInternational%3C%2Ftitle%3E%3Cg%20class%3D%22PhoneInputInternationalIconGlobe%22%20stroke%3D%22currentColor%22%20fill%3D%22none%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%3E%3Cpath%20stroke-linecap%3D%22round%22%20d%3D%22M47.2%2C36.1C48.1%2C36%2C49%2C36%2C50%2C36c7.4%2C0%2C14%2C1.7%2C18.5%2C4.3%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M68.6%2C9.6C64.2%2C12.3%2C57.5%2C14%2C50%2C14c-7.4%2C0-14-1.7-18.5-4.3%22%3E%3C%2Fpath%3E%3Cline%20x1%3D%2226%22%20y1%3D%2225%22%20x2%3D%2274%22%20y2%3D%2225%22%3E%3C%2Fline%3E%3Cline%20x1%3D%2250%22%20y1%3D%221%22%20x2%3D%2250%22%20y2%3D%2249%22%3E%3C%2Fline%3E%3Cpath%20stroke-linecap%3D%22round%22%20d%3D%22M46.3%2C48.7c1.2%2C0.2%2C2.5%2C0.3%2C3.7%2C0.3c13.3%2C0%2C24-10.7%2C24-24S63.3%2C1%2C50%2C1S26%2C11.7%2C26%2C25c0%2C2%2C0.3%2C3.9%2C0.7%2C5.8%22%3E%3C%2Fpath%3E%3Cpath%20stroke-linecap%3D%22round%22%20d%3D%22M46.8%2C48.2c1%2C0.6%2C2.1%2C0.8%2C3.2%2C0.8c6.6%2C0%2C12-10.7%2C12-24S56.6%2C1%2C50%2C1S38%2C11.7%2C38%2C25c0%2C1.4%2C0.1%2C2.7%2C0.2%2C4c0%2C0.1%2C0%2C0.2%2C0%2C0.2%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3Cpath%20class%3D%22PhoneInputInternationalIconPhone%22%20stroke%3D%22none%22%20fill%3D%22currentColor%22%20d%3D%22M12.4%2C17.9c2.9-2.9%2C5.4-4.8%2C0.3-11.2S4.1%2C5.2%2C1.3%2C8.1C-2%2C11.4%2C1.1%2C23.5%2C13.1%2C35.6s24.3%2C15.2%2C27.5%2C11.9c2.8-2.8%2C7.8-6.3%2C1.4-11.5s-8.3-2.6-11.2%2C0.3c-2%2C2-7.2-2.2-11.7-6.7S10.4%2C19.9%2C12.4%2C17.9z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E")',
        borderRadius: "5px",
        order: 2,
      } : {
        ...reactSelectStyles.input(base)[":before"],
        content: '""',
        minWidth: "25px", // the width of the flag
        height: "18px",
        order: 2,
      }),
      ":after": {
        ...reactSelectStyles.input(base)[":after"],
        content: '"\u00a0\u00a0"', // 2 spaces after the flag
        order: 0
      }
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: "#333333",
      borderRadius: "20px",
      border: "none",
      boxShadow: "none", 
    }),
};

function formatOptionLabel({value, label, code}) {
    const Flag = flags[value];
    return (
        <div style={{ display: "flex", alignItems:"center" }}>
            <Flag width="25px" className="countryFlagIcon" style={{minWidth: "25px", marginTop: "1px"}}/>&nbsp;&nbsp;
            <span>{label}</span>
        </div>
    );
}

function TwinzoCountrySelect({id="", required=false, value=null, setValue, valid, setValid, className="", label, menuPlacement="top"}) {
    const countryOptions = useMemo(() => countryList().getData(), []);
        
    return (
        <FloatedSelect id={id} className={className} required={required} options={countryOptions} value={value} onChange={setValue} menuPlacement={menuPlacement}
        valid={valid} setValid={setValid} reactSelectElementStyles={extendedReactSelectStyles}
        label={label} formatOptionLabel={formatOptionLabel}/>
    )
};

export default memo(TwinzoCountrySelect);

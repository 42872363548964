import React, { useState } from "react";
import RegistrationUser from "./RegistrationUser.js";
import RegistrationLaRucheEmailEndOfFlow from "./RegistrationLaRucheEmailEndOfFlow.js";

/**
 * 0 = user registration step
 * 1 = email was sent step
 */
const LAST_ACTIVE_STEP = 1;

const RegistrationLaRucheUserDialog = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === LAST_ACTIVE_STEP || activeStep === 1)
      window.location.href = process.env.REACT_APP_TWINZO_MAIN_DOMAIN.trim();
    else
      setActiveStep((prevStep) => prevStep + 1);
  };

  const handleSkip = () => {
    setActiveStep((prevStep) => prevStep + 1);
  }

  const handleCancel = () => {
  };

  return (
    <div className="dialog-container d-flex justify-content-center align-items-center flex-column">
      <div className="row">
        <div>
          {activeStep + 1 > 0 && (
          <div className="d-flex justify-content-end">
            {/* Paging element */}
            <div className="paging">{activeStep + 1}/{LAST_ACTIVE_STEP + 1}</div>
          </div>)}
          <div className="borderedDialog">
            <div className="dialog position-relative">
              <div className="">
                {activeStep === 0 && (
                  <RegistrationUser
                    onNext={handleNext}
                    onCancel={handleCancel}
                    showCancel={true}
                    showPrevious={false}
                    showNext={true}
                    showSkip={false}
                    nextButtonText="Registration"
                  />
                )}
                {activeStep === 1 && (
                  <RegistrationLaRucheEmailEndOfFlow
                    onNext={handleNext}
                    onCancel={handleCancel}
                    onSkip={handleSkip}
                    showCancel={false}
                    showPrevious={false}
                    showNext={true}
                    showSkip={false}
                    nextButtonText="Ok"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationLaRucheUserDialog;

import React from "react";
import RegistrationForm from "../RegistrationForm.js";

const RegistrationLaRucheEmailEndOfFlow = ({ onNext, onCancel, onSkip, showCancel, showPrevious, showNext, showSkip, nextButtonText }) => {

  return (
    <RegistrationForm
      onNext={onNext}
      onSkip={onSkip}
      onCancel={onCancel}
      isInputValid={true}
      showCancel={showCancel}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>Almost done!</h4>
        <div className="dialogContent">
          <p>
            An activation email has been sent to your mailbox. Please follow the instructions in the email to activate your account and start using LaRuche Industrielle digital twin. 
          </p>
          <table className="mt-4 mx-4">
            <tbody>
              <tr>
                <td className="pe-4">
                  <a className="leading-trim-disable" href="https://apps.apple.com/us/app/twinzo-digital-twin/id1561970281" id="LaRucheAppStore">
                    <img src="/appstore_badge.png" className="img-fluid" alt="Appstore badge"></img>
                  </a>
                </td>
                <td>
                  <a className="leading-trim-disable" href="https://play.google.com/store/apps/details?id=eu.twinzo.digitaltwin&pli=1" id="LaRucheGooglePlay">
                    <img src="/google_play_badge.png" className="img-fluid" alt="Google play badge"></img>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationLaRucheEmailEndOfFlow;

import { useEffect, useRef } from "react";

/**
 * A Text which rolls on mouse hover if it overflows inside its container.
 * @param {string} text - The text to display.
 * @param {React.ref} parentRef - Ref to the element on which to register onMouseEnter events that trigger roll.
 * @returns {React.Component}
 */
function RollingOverflowText({text, parentRef}) {
    const wrapperRef = useRef();

    useEffect(() => {
        const wrapperElement = wrapperRef.current;
        const parentElement = parentRef?.current;

        const roll = () => {
            const wrapperParentCssStyles = window.getComputedStyle(wrapperElement.parentNode, null);
            
            let wrapperParentPaddingRight = parseFloat(wrapperParentCssStyles.getPropertyValue("padding-right").slice(0,-2)); // remove px from the end
            let wrapperParentPaddingLeft = parseFloat(wrapperParentCssStyles.getPropertyValue("padding-left").slice(0,-2)); // remove px from the end

            const wrapperWidth = wrapperElement.clientWidth;
            const wrapperParentWidth = wrapperElement.parentNode.clientWidth - wrapperParentPaddingRight - wrapperParentPaddingLeft;

            const transitionDuration = Math.abs(wrapperParentWidth - wrapperWidth) / document.documentElement.clientWidth * 2;
            if (wrapperWidth > wrapperParentWidth) {
                wrapperElement.style.transition = `transform ${transitionDuration}s linear`;
                wrapperElement.style.transform = `translate(${(wrapperParentWidth) - wrapperWidth}px, 0)`;
            }
        };
        const rollBack = () => {
            if (wrapperElement.clientWidth > wrapperElement.parentNode.clientWidth) 
                wrapperElement.style.transform = "translate(0, 0)";
        };

        if (parentElement) {
            parentElement.addEventListener('mouseenter', roll);
            parentElement.addEventListener('mouseleave', rollBack);
        } else {
            wrapperElement.addEventListener('mouseenter', roll);
            wrapperElement.addEventListener('mouseleave', rollBack);
        }

        // set up an observer, such that when text changes, reset translation
        const observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (mutation.type === 'characterData') {
                    wrapperElement.style.transition = "none";
                    wrapperElement.style.transform = "translate(0, 0)";
                }
            };
        });
        // Configuration of the observer
        const config = { characterData: true, attributes: false, childList: true, subtree: true};
        // Start observing the target node for configured mutations
        observer.observe(wrapperElement, config);
    
        // Cleanup function to disconnect the observer when the component unmounts
        return () => {
            observer.disconnect();
            if (parentElement) {
                parentElement.removeEventListener('mouseenter', roll);
                parentElement.removeEventListener('mouseleave', rollBack);
            } else if (wrapperElement) {
                wrapperElement.removeEventListener('mouseenter', roll);
                wrapperElement.removeEventListener('mouseleave', rollBack);
            }
        }; // eslint-disable-next-line
    }, []);

    return (
        <div className="rollingTextWrapper" ref={wrapperRef}>
            {text}
        </div>
    );
}

export default RollingOverflowText;
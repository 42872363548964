import { useState, useRef, useEffect, memo } from 'react';
import HorizontalScroll  from "./HorizontalScroll";
import RollingOverflowText from './RollingOverflowText';
import ModelState from "../shared/Helpers/ModelState";

function TagBox({ className = "", maxTagCount = 1000, id="", label="", inputType="text", tags, setTags, valid, setValid, name, validationOptions=[], required=false, 
    requiredErrorMessage=label.replace(/\.*$/, '') + " are required.", tagValidation }) {
    const inputElement = useRef(null);
    const horizontalMenuRef = useRef(null);

    const [errorMessage, setErrorMessage] = useState(null);
    const [isDisplayingError, setIsDisplayingError] = useState(false); // after first blur this is set to true and stays true

    useEffect(() => {
        if (name) {
          ModelState.register(name, (errorMsg) => {
            setErrorMessage(errorMsg);
            setIsDisplayingError(true);
            setValid(false);
          }, () => {
            setErrorMessage("");
            setIsDisplayingError(false);
          })
        }
    
        return () => ModelState.unRegister(name);
    }, [name, setValid]); // on mount

    useEffect(() => {
        let validationResult = true;

        if (required && tags.length === 0) {
            validationResult = false;
            setErrorMessage(requiredErrorMessage); // remove trailing ... from the string
        }
        if (validationResult && tags.length) {
            for (const validationOption of validationOptions) {

                const isValid =  validationOption.validate(tags);
                if (!isValid) {
                    validationResult = false;
                    setErrorMessage(validationOption.errorMessage);
    
                    break;
                }
            }
        }

        setValid(validationResult);
    }, [tags, validationOptions, required, requiredErrorMessage, setValid]);

    return (
        <HorizontalScroll horizontalScrollContainerRef={horizontalMenuRef}>
        <div className='position-relative' onBlur={(_) => setIsDisplayingError(true)}>
            <div className={"tagBox horizontalScroll form-floating " + className + ((!valid && isDisplayingError) ? " invalid-input" : "")} onClick={(e) => { 
                if (!e.target.classList.contains("btn-close-small")) { 
                    inputElement.current.focus();
                    inputElement.current.scrollIntoView();
                } }}
            onMouseDown={(e) => { e.preventDefault(); }} ref={horizontalMenuRef}>
                {tags.map((tag, index) => (
                    <span className="tag leading-trim" key={tag.value + index}>{tag.value}
                    {tag.notRemovable ?? 
                        <button className="btn btn-close-small leading-trim-x-height" type='button' onClick={(e) => {
                            setTags((previousTags) => previousTags.filter(t => t !== tag))
                            }} aria-label='Remove'><span className="closeSign">&times;</span></button>}
                    </span>
                ))}
                <input type={inputType} className={`form-control${tags.length ? "" : " w-100"}${tags.length === maxTagCount ? " w-0 px-0" : ""}`} placeholder="" id={id}
                ref={inputElement}
                disabled={tags.length === maxTagCount}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        const newTag = e.target.value.trim();

                        if (newTag && !tags.find(tag => tag.value === newTag)) {
                            const isTagValid = tagValidation ? tagValidation(newTag) : true;
                            if (isTagValid) {
                                setTags((oldTags) => [...oldTags, { value: newTag}]);
                                e.target.value = "";
                                e.target.focus();
                            }
                        }
                    } else {
                        inputElement.current.scrollIntoView();
                    }
                }}
                />
                <label htmlFor={id}><RollingOverflowText text={(isDisplayingError && !valid) ? errorMessage : label}></RollingOverflowText></label>
            </div>
        </div>
        </HorizontalScroll>
    );
}

export default memo(TagBox);
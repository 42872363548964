import React, { useEffect } from 'react';

/**
 * This component expects a scrollable div passed to it as children.
 * @param {React.Ref} horizontalScrollContainerRef - A ref to the div.
 */
const HorizontalScroll = ({horizontalScrollContainerRef, children,}) => {
    useEffect(() => {
        if (horizontalScrollContainerRef.current) {
            const resizeFade = (event) => {
                const totalWidth = horizontalScrollContainerRef.current.scrollWidth;
                const visibleWidth = horizontalScrollContainerRef.current.clientWidth;

                const pixelsUntilFullyScrolled = Math.abs(horizontalScrollContainerRef.current.scrollLeft + visibleWidth - totalWidth);
                if (pixelsUntilFullyScrolled < 20 + 9) // if zoomed to the end. 20 is size of fade and 9 is margin
                    horizontalScrollContainerRef.current.style.setProperty('--fadeWidth', pixelsUntilFullyScrolled - 9 + "px");
                else
                    horizontalScrollContainerRef.current.style.setProperty('--fadeWidth', "20px");
            }

            const onWheelScrollHorizontally = (event) => {
                const totalWidth = horizontalScrollContainerRef.current.scrollWidth;
                const visibleWidth = horizontalScrollContainerRef.current.clientWidth;
                const scrollAmount = 50
                const scrollDirection = event.deltaY && event.deltaY / Math.abs(event.deltaY); // +1 is to the left, -1 is to the right

                if ((scrollDirection === 1 && Math.abs((totalWidth - (visibleWidth + horizontalScrollContainerRef.current.scrollLeft))) > 2)
                    || (scrollDirection === -1 && (horizontalScrollContainerRef.current.scrollLeft > 2))) {
                        event.preventDefault();
                        horizontalScrollContainerRef.current.scrollLeft += scrollAmount * scrollDirection;
                        if (horizontalScrollContainerRef.current.scrollLeft + visibleWidth > totalWidth)
                            horizontalScrollContainerRef.current.scrollLeft = totalWidth - visibleWidth;
                }
            };
            horizontalScrollContainerRef.current.addEventListener('wheel', onWheelScrollHorizontally, { passive: false });

            horizontalScrollContainerRef.current.addEventListener("scroll", resizeFade, { passive: true });

            const element = horizontalScrollContainerRef.current;
            return () => {
                element.removeEventListener('wheel', onWheelScrollHorizontally);
                element.removeEventListener('scroll', resizeFade);
            };
        }
    }, [horizontalScrollContainerRef]);

    return (
        <>
            {children}
        </>
    )
};

export default HorizontalScroll;
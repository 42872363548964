import React from 'react';
import RegistrationForm from '../RegistrationForm';

const RegistrationFinalScreenForm = ({onNext, onCancel, showCancel, showPrevious, showNext, showSkip, nextButtonText}) => {

  return (
    <RegistrationForm
      onNext={onNext}
      onCancel={onCancel}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
      isInputValid={true}
    >
      <>
        <h4>Almost done!</h4>
        <div className="dialogContent">
          <p>Thank you for completing the onboarding process! An activation email has been sent to your mailbox. Please follow the instructions in the email to activate your account and start using your digital twin.</p>
          <p className='mt-3'>If you provided a 3D model, we are currently processing it. This can take a few minutes, and we will notify you via email once it's ready. Thank you for your patience!
          </p>
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationFinalScreenForm;
import { memo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const SwitchButton = ({ value, setValue, id="", width="25%", ...rest }) => {

    return (
        <div className="SwitchButtonWrapper" role="switch" id="DropDownAdvancedModelSettingsModelHasHierarchy" aria-checked={value} style={{width: width}} {...rest}>
            <span className="slider"></span>
            <button type="button" aria-label='Off' onClick={(e) => setValue(false)} aria-pressed={!value}><CloseIcon className='icon'/></button>
            <button type="button" aria-label='On' onClick={(e) => setValue(true)} aria-pressed={value}><CheckIcon className='icon'/></button>
        </div>
    );
};

export default memo(SwitchButton);
import { useMemo, useCallback, useEffect, useState, memo } from "react";
import Select from 'react-select'
import { reactSelectLabel, reactSelectStyles } from "../shared/Constants";
import ModelState from "../shared/Helpers/ModelState";

function FloatedSelect({id="", required, options, value=null, setValue, valid, setValid, name, validationOptions=[], className="", label, requiredErrorMessage=label + " is required.",
    placeholder="", menuPlacement="top", reactSelectElementStyles=reactSelectStyles, ...rest}) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [isDisplayingError, setIsDisplayingError] = useState(false); // after first blur this is set to true and stays true
    const SelectLabel = useMemo(() => reactSelectLabel(id), [id]);

    useEffect(() => {
        if (name) {
          ModelState.register(name, (errorMsg) => {
            setErrorMessage(errorMsg);
            setIsDisplayingError(true);
            setValid(false);
          }, () => {
            setErrorMessage("");
            setIsDisplayingError(false);
          })
        }
    
        return () => ModelState.unRegister(name);
    }, [name, setValid]); // on mount
    
    const validate = useCallback((inputValue) => {
        let validationResult = true;

        if (required && !inputValue) {
            validationResult = false;
            setErrorMessage(requiredErrorMessage);
        }
        if (validationResult && inputValue) {
            for (const validationOption of validationOptions) {

                const isValid = validationOption.pattern ? validationOption.pattern.test(inputValue) : validationOption.validate(inputValue);
                if (!isValid) {
                    validationResult = false;
                    setErrorMessage(validationOption.errorMessage);
    
                    break;
                }
            }
        }

        setValid(validationResult);
    }, [required, setValid, validationOptions, requiredErrorMessage]);

    useEffect(() => {
        validate(value);
    }, [value, required, validationOptions, validate]);
        
    return (
        <Select id={id} className={className + ((isDisplayingError && !valid) ? " invalid-input" : "")} required={required} placeholder={placeholder}
        options={options} value={value} onChange={(newValue) => { 
            setIsDisplayingError(true); setValue(newValue);
        }} menuPlacement={menuPlacement} styles={reactSelectElementStyles} components={{ Control: SelectLabel }}
        onBlur={(_) => setIsDisplayingError(true)}
        data-label={(isDisplayingError && !valid) ? errorMessage : label}
        {...rest}/>
    )
};

export default memo(FloatedSelect);

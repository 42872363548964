import React, { useEffect, } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ReCaptcha = ({isCaptchaVerified, setIsCaptchaVerified}) => {
  // This is used to set ReCaptcha verification to verified for integration test purposes
  useEffect(() => {
    // This [true] value should be changed depending on the set config from the file .env
    // If the value is [true], the capthca will not be shown on the page and the variable isCaptchaVerified will be set to true
    // If the value is [false], the capthca will be shown on the page and the variable isCaptchaVerified will be set to false until we click the checkbox
    setIsCaptchaVerified(process.env.REACT_APP_ENV.trim() !== "Release");
  }, [setIsCaptchaVerified]);

  const handleCaptchaChange = (value) => {
    setIsCaptchaVerified(!!value);
  };

  return (
    (isCaptchaVerified === false) ??
        <div className="catpcha mt-3 w-100">
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY.trim()}
                stoken={process.env.REACT_APP_RECAPTCHA_SECURE_TOKEN.trim()}
                onChange={handleCaptchaChange}
                theme="dark"
                size="normal"
            />
        </div>
  );
};

export default ReCaptcha;

import { createContext, useContext, useState } from "react";
import Spinner from './Spinner.js';

export const LoadingContext = createContext();

export function LoadingProvider({ children }) {

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(false);
  const [textHeader, setTextHeader] = useState("Processing your request...");
  const [textMessage, setTextMessage] = useState("");
  const value = { loading, setLoading, textHeader, setTextHeader, textMessage, setTextMessage, progress, setProgress };
  
  return (
    <LoadingContext.Provider value={value}>{children}
      {
        loading && 
        (<div className="modal-overlay d-flex justify-content-center align-items-center">
          <Spinner />
        </div>)
      }
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}
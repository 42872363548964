import { components } from "react-select";
import RollingOverflowText from "../components/RollingOverflowText";

/**
 * An array of extensions that backend accepts, without dots. Example: ["fbx", "obj"]
 * @type {string[]}
 */
export const RAW_MODEL_FILE_EXTENSIONS = ["fbx", "obj"];
export const RAW_MODEL_TEXTURE_FILE_EXTENSIONS = ["png", "jpg", "mtl"];
export const ALLOWED_MODEL_FILE_EXTENSIONS = [
  ...RAW_MODEL_FILE_EXTENSIONS,
  ...RAW_MODEL_TEXTURE_FILE_EXTENSIONS,
  "zip",
];
export const ONE_MB_IN_BYTES = 1024 * 1024;
export const ONE_HUNDRED_MB_IN_BYTES = 100 * ONE_MB_IN_BYTES;

const reactSelectButtonArrowTextShadow =
  "drop-shadow(0 1px 1px rgba(0, 0, 0, 0.8))"; // it is a svg not text so text-shadow-like effect is applied like this
export const reactSelectStyles = {
  control: (base, { selectProps }) => {
    const isValid = !selectProps.className.includes("invalid-input");
    return {
      ...base,
      cursor: "pointer",
      backgroundColor: !isValid ? "#330506" : "#101010",
      transition: "background-color .1s",
      borderRadius: "7px",
      border: "none",
      //outline: !hasValue ? "3px solid #ff5055" : "transparent",
      "::before": {
        // Rather then using outline, use this so that the dropdown can be rendered below it
        content: '""' /* Required for pseudo-elements */,
        position: "absolute" /* Position it absolutely within the parent */,
        top: "-2px" /* Adjust based on the thickness of the outline */,
        left: "-2px" /* Adjust based on the thickness of the outline */,
        right: "-2px" /* Adjust based on the thickness of the outline */,
        bottom: "-2px" /* Adjust based on the thickness of the outline */,
        border: !isValid
          ? "3px solid #ff5055"
          : "3px solid transparent" /* Outline color and thickness */,
        borderRadius: "7px",
        pointerEvents:
          "none" /* Ensures the pseudo-element does not interfere with user interactions */,
        boxSizing: "border-box" /* Ensure the sizing includes the border */,
        zIndex: 4,
        transition: "border-color .1s",
      },
      boxShadow: "none",
    };
  },
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    color: "#ffffff",
    cursor: "pointer",
    backgroundColor: isSelected
      ? "#282828!important"
      : isFocused
      ? "#282828!important"
      : "#101010!important",
  }),
  singleValue: (base) => ({
    ...base,
    color: "#ffffff",
    padding: "0",
    margin: "0",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    borderTopRightRadius: "7px",
    borderBottomRightRadius: "7px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#ffffff",
    backgroundColor: "#3afc97",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
    transition:
      "color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out",
    ":hover": {
      backgroundColor: "#333333",
    },
    ":active": {
      backgroundColor: "#4D4D4D",
    },
    boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.4)",
    height: "58px",
    width: "58px",
    alignItems: "center",
    justifyContent: "center",
    svg: {
      width: "30px",
      height: "30px",
      filter: reactSelectButtonArrowTextShadow,
    },
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 2,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 3,
    margin: 0,
    backgroundColor: "#101010",
    borderRadius: "7px",
    overflow: "hidden",
    width: "calc(100% - 58px)",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  valueContainer: (base, { isFocused, hasValue }) => ({
    // this is to move it down somewhat, for floating label
    ...base,
    padding: "1rem 0.75rem",
    lineHeight: "1.25",
    maxHeight: "calc(3.5rem + calc(var(--bs-border-width) * 2))",
    paddingTop: hasValue ? "1.625rem" : "",
    paddingBottom: hasValue ? "0.625rem" : "",
    opacity: isFocused || hasValue ? "1" : "0",
  }),
  input: (base) => ({
    ...base,
    padding: "0",
    margin: "0",
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    margin: "0",
  }),
};

export const reactSelectLabel = (forElementId) => {
  return (props) => {
    const style = {
      // these are used in addition to bootstrap's
      transform: props.hasValue
        ? "scale(0.8) translateY(-0.5rem) translateX(0.1rem)"
        : "",
      color: props.selectProps.className.includes("invalid-input")
        ? "#ff5055"
        : props.hasValue
        ? "#AAAAAA"
        : "",
      maxWidth: props.hasValue
        ? "calc((100% - 58px) / 0.8)"
        : "calc(100% - 58px)",
      cursor: "pointer",
    };
    if (
      !props.hasValue &&
      props.selectProps.className.includes("invalid-input")
    )
      style.transition = "opacity 0.1s ease-in-out, transform 0.1s ease-in-out"; // Do not transition color, since it looks bad if non floated label goes from white to red slowly

    return (
      <>
        <label htmlFor={forElementId} style={style}>
          <RollingOverflowText
            text={props.selectProps["data-label"]}
          ></RollingOverflowText>
        </label>
        <components.Control {...props} />
      </>
    );
  };
};

export const ReactSelectDropdownIndicator = () => {
  return (
    <svg
      height="30"
      width="30"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      style={{
        display: "inline-block",
        fill: "currentColor",
        lineHeight: 1,
        stroke: "currentColor",
        strokeWidth: 0,
        filter: reactSelectButtonArrowTextShadow,
      }}
    >
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

export const GENERIC_EMAIL_ADDRESSES = [
  "gmail.com",
  "yahool.com",
  "outlook.com",
  "aol.com",
  "hotmail.co.uk",
  "hotmail.com",
  "hotmail.fr",
  "msn.com",
  "yahoo.fr",
  "outlook.com",
];

function getDecimalSeparator(locale) {
  const numberWithDecimalSeparator = 1.1;

  return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
}
/**
 * Decimal separator of current locale. Could be either '.' or ','.
 */
export const DECIMAL_SEPARATOR = getDecimalSeparator();

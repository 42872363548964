import React, { useEffect, useState } from 'react';
import { useLoading } from './LoadingProvider.js';
import spinnerCircleBlue from '../content/images/SemiCircleBlue.png'
import spinnerCircleGreen from '../content/images/SemiCircleGreen.png';
import spinnerCirclePurple from '../content/images/SemiCirclePurple.png';

const Spinner = () => {
  const { textHeader, textMessage, loading, progress } = useLoading();
  const [isTakingLong, setIsTakingLong] = useState(false);
  
  useEffect(() => {
    const takingTooLongTimeout = setTimeout(() => setIsTakingLong(true), 20_000);

    return () => {
      clearTimeout(takingTooLongTimeout);
    }
  }, []);

  /// <summary>
  /// Renders the progress bar if the loading is true and the progress is greater than 0
  /// </summary>
  const RenderProgress = () => {
    return (loading && (progress > 0)) &&
    <div>
      <p>Progress: {progress}%</p>
    </div>
  };

  return (
    <div className="spinner-container">
      <div className="overlay"></div> {/* Overlay to reduce opacity */}
      <div className="spinner">
        <div>
          {/* Loading spinner */}
          <img
            src={spinnerCircleGreen}
            alt="Spinner"
            className="greenSpinnerCircle"
          />
          <img
            src={spinnerCircleBlue}
            alt="Spinner"
            className="blueSpinnerCircle"
          />
          <img
            src={spinnerCirclePurple}
            alt="Spinner"
            className="purpleSpinnerCircle"
          />
        </div>
      </div>
      <div className='spinner-text text-center'>
        <h2 className="">{textHeader}</h2>
        <p className="">{textMessage}</p>
        {RenderProgress()}
        {isTakingLong && !progress && (<p>Looks like our servers are busy. Please stay patient, this may take a few minutes...</p>)}
      </div>
    </div>
  );
}

export default Spinner;